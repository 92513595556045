import React from "react";
import cn from "clsx";
import { PrismicRichText } from "@prismicio/react";
import { Content } from "@prismicio/client";
import { useTranslation } from "next-i18next";
import { URL_PAGE_MEN, URL_PAGE_WOMEN } from "@middleware/constants";
import { Link } from "@components/ui";
import { useWindow } from "@middleware/hooks";
import MobileImage from "@static/images/bg/new-head-feature-mobile.png";
import Image from "next/image";
import style from "./HomeHead.module.scss";

export default function MainHead({ slice }: { slice: Content.HomeHeadSlice }) {
  const { isDesktop } = useWindow();
  const { t } = useTranslation();
  const bgImageStyle = {
    backgroundImage: isDesktop
      ? `url(${slice.primary.backgroundImage.url as string})`
      : "none",
  };

  return (
    <section>
      <div className={cn("head", style.head)}>
        <div
          className={cn(style.feature, {
            [style.auto]: slice.primary.autoHeight,
          })}
          style={bgImageStyle}
        >
          <Image src={MobileImage} alt="" className={style.mobile} />
        </div>
        <div className={cn("lk-wrap", style.wrap)}>
          <div className={style.content}>
            <div className={style.title}>
              <PrismicRichText field={slice.primary.title} />
            </div>
            <div className={style.subtitle}>
              <PrismicRichText field={slice.primary.subtitle} />
            </div>
            <div className={style.description}>
              <PrismicRichText field={slice.primary.description} />
            </div>
            <div className={style.action}>
              <Link href={URL_PAGE_WOMEN} className="default">
                {t("buttons.woman")}
              </Link>
              <Link href={URL_PAGE_MEN} className="default">
                {t("buttons.man")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
